const user = {
  theme: "light",
  screen: {
    isSidenavBarExpanded: true,
  },
};

export const stateData = {
  user,
};

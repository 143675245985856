// Import required libraries
import React from "react";

// Import data
import comingSoon from "../../../assets/images/work_in_progress.svg";
import { config } from "../../config/config";

//Import styles
import "./WorkInProgress.scss";

function WorkInProgress() {
  return (
    <div className="work-in-progress-container">
      <div className="wip-image">
        <img src={comingSoon} alt="Work in progress" />
      </div>
      <div>
        <p className="work-in-progress-text">
          {config.messages.workInProgressMessage}
        </p>
      </div>
    </div>
  );
}

export default WorkInProgress;

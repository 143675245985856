/*-------------------------Actual APIs-------------------------*/
const backendApi = "/api";
const signInUrl = `/auth/sign_in?post_sign_in_url=https%3A%2F%2F`;
const signOutUrl = `/auth/sign_out`;
const authUrl = `${backendApi}/userinfo`;

const messages = {
  workInProgressMessage: "Your content here.",
  authenticatingUserMessage: "Authenticating user",
};

const hardCoded = { homeLocation: "/home", appName: "Base App" };

const api = {
  authUrl,
  signInUrl,
  signOutUrl,
};

const config = {
  footerText: "Powered by RBOne",
  api,
  messages,
  hardCoded,
};

export { config };
